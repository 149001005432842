import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Bio from '../components/blog/bio';
import Layout from '../components/blog/layout';
import { SliceZone } from '../components/SliceZone';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.prismicBlogPost.data;
  const { previous, next } = data;

  return (
    <Layout
      location={location}
      pageTitle={`${post.html_title || post.title.richText[0].text} | Adminmate`}
      pageDesc={post.html_desc}
    >
      <div className="container max-w-screen-lg mx-auto">
        <div className="w-5/6 md:w-4/5 mx-auto my-12">
          <article
            itemScope
            itemType="http://schema.org/Article"
          >
            <Link to="/blog/" rel="prev" className="text-amblue hover:opacity-90 uppercase text-sm tracking-wide font-black">← All posts</Link>
            <header>
              <h1
                itemProp="headline"
                className="text-3xl mt-2 tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl">
                  {/* {post.data.title} */}
                  {RichText.asText(post.title.richText)}
              </h1>
              <time className="uppercase text-xs text-gray-500 font-bold mt-2">{post.date}</time>
            </header>
            {/* <section
              dangerouslySetInnerHTML={{ __html: post.html }}
              itemProp="articleBody"
              className="mt-8 text-gray-800 leading-7 post-body"
            /> */}
            <section className="mt-8 text-gray-800 leading-7 post-body">
              <SliceZone slices={post.body} />
            </section>
          </article>
        </div>
      </div>

      <hr className="w-full bg-gray-100 my-12" style={{height: '1px'}} />

      <footer>
        <Bio />
      </footer>

      <hr className="w-full bg-gray-100 my-12" style={{height: '1px'}} />

      <div className="container max-w-screen-lg mx-auto">
        <div className="w-5/6 md:w-4/5 mx-auto my-12">
          <nav className="flex justify-between">
            <div className="w-1/2">
              {previous && (
                <>
                  <div className="mb-2">
                    <Link to={`/blog/${previous.uid}`} rel="prev" className="text-amblue hover:opacity-90 uppercase text-sm tracking-wide font-black">← Previous post</Link>
                  </div>
                  <Link to={`/blog/${previous.uid}`} rel="prev" className="text-md md:text-2xl font-semibold md:font-black">
                    {previous.data.title.richText[0].text}
                  </Link>
                </>
              )}
            </div>
            <div className="w-1/2 text-right">
              {next && (
                <>
                  <div className="mb-2">
                    <Link to={`/blog/${next.uid}`} rel="next" className="text-amblue hover:opacity-90 uppercase text-sm tracking-wide font-black">Next post →</Link>
                  </div>
                  <Link to={`/blog/${next.uid}`} rel="next" className="text-md md:text-2xl font-semibold md:font-black">
                    {next.data.title.richText[0].text}
                  </Link>
                </>
              )}
            </div>
          </nav>
        </div>
      </div>
    </Layout>
  )
};

export default BlogPostTemplate;

export const query = graphql`
  query BlogPostQuery(
    $id: String
    $previousPostId: String
    $nextPostId: String
  ) {
    prismicBlogPost(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        date(formatString: "MMM D, YYYY")
        html_title
        html_desc
        title {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PostDataBodyText
          ...PostDataBodyQuote
          ...PostDataBodyImageWithCaption
        }
      }
    }
    previous: prismicBlogPost(id: { eq: $previousPostId }) {
      uid
      data {
        title {
          richText
        }
      }
    }
    next: prismicBlogPost(id: { eq: $nextPostId }) {
      uid
      data {
        title {
          richText
        }
      }
    }
  }
`;
